<template>
<div id="MHSneakers" v-lazy-container="{ selector: 'img' }">
	
	<vue-headful title="Gouwudan – Morten Hauge"/>
	
	<section class="wrapper wrapper-darkest">
		
		<img :data-src="images[0].url" class="hero-img-case-study" :alt="images[0].alt">

		<section class="content-case-study">
			
			<!-- <img :data-src="images[15].url" class="img-full" :alt="images[15].alt"> -->

			<h1>Gouwudan</h1>
			
			<p class="intro_text">
				
				Gouwudan – chinese for shopping list.

				<br><br>

				Even though I worked at a grocery store for eleven years, it's a place I like to spend as little time as possible.

				<br><br>
				
				The solution was creating this shopping list app that knows the best routes between the shelves in the store. 

				<br><br>

				All design work and front-end development done by me.
		
			</p>
		
			<div class="button-container">
				<a href="https://morten-hauge.com/gouwudan/#/" target="_blank"><span class="button">Check out live website</span></a>
				<a href="https://github.com/Morten-Hauge/Gouwudan" target="_blank"><span class="button">Check out code on GitHub</span></a>
			</div>

		</section>
	
	</section>

	<section class="wrapper wrapper-dark">
		
		<section class="content-case-study" v-scrollanimation>

			<div class="text">

				<h2>Background and preparation</h2>

				<div class="grid2">

					<p>
				
						Previously I've been using the Apple Notes app as my shopping list. I would usually just throw anything I needed in a new note, and then move the items around and divide them into different groups depending on where in the store they are located.

					</p>

					<figure v-scrollanimation2 >
						<img :data-src="images[1].url" :alt="images[1].alt">
						<figcaption :class="images[1].captionClass">{{images[1].caption}}</figcaption>
					</figure>

					<figure v-scrollanimation2 >
						<img :data-src="images[2].url" :alt="images[2].alt">
						<figcaption :class="images[2].captionClass">{{images[2].caption}}</figcaption>
					</figure>

					<p v-scrollanimation>
				
						The biggest issue with the Apple Notes way is that I had to think about what items should be grouped together everytime I made a new shopping list.
						
						<br><br>

						So I thought to myself – what if I could do this grouping once and some magical app could show me the quickest way through the store.
						I started with making a blueprint of the store and dividing it into different zones. I then went on to creating a pretty long JSON file with my most common items and assigning them to the different zones.

						<br><br>

						The goal was to show the items in the order of the zones 1 through 8, which I knew was the quickest and most effective route through the store.

					</p>

				</div>
		
			</div>
		
		</section>
	
	</section>	
	
	<section class="wrapper wrapper-darkest">
		
		<section class="content-case-study" v-scrollanimation>

			<h2 class="learned">State management and local storage</h2>

			<p>
				
				Gouwudan has two views – Home view with all the items I might want to buy, and Store view with the items I’ve picked from Home view. I knew I needed one array with items for each view. And I didn’t want to pick everything again if the browser reloaded the page. 

				<br><br>

				The solution was to use the power of a Vue plugin called VuexPersist. This plugin helped me a lot regarding pushing the arrays to local storage, so the data wasn’t lost even though I closed or reloaded the browser.
				
				<br><br>

				The items in Home view are sorted alphabetically. In the Store view the items are sorted and viewed in their designated zone in the store. 

			</p>

			<div class="grid3">
				
				<figure v-scrollanimation2>
					<img :data-src="images[3].url" :alt="images[3].alt">
					<figcaption :class="images[3].captionClass">{{images[3].caption}}</figcaption>
				</figure>

				<figure v-scrollanimation2>
					<img :data-src="images[4].url" :alt="images[4].alt">
					<figcaption :class="images[4].captionClass">{{images[4].caption}}</figcaption>
				</figure>

				<figure v-scrollanimation2 >
						<img :data-src="images[5].url" :alt="images[5].alt">
						<figcaption :class="images[5].captionClass">{{images[5].caption}}</figcaption>
				</figure>

			</div>

		</section>
	
	</section>

	<section class="wrapper wrapper-dark">
		
		<section class="content-case-study" v-scrollanimation>

			<h2>Conclusion and further development</h2>

			<p>
				This little web app might seem like something super simple. But I worked on and off on this projects for months. Just getting the two views to talk to each other was pretty challenging. Once again I learned a lot about Vue and how JavaScript frameworks and libraries work. And I solved a real life problem for myself so I can spend more time learning more about front-end development.

				<br><br>

				Two drawbacks with this version is that it only works for Rema 1000 Galgeberg, and I can't add new items on the fly.

				<br><br>

				<b>Functionality I might add later:</b>

				<br><br>

				– A search function for the home view would  be nice. Now I’ve sorted all the items alphabetically, which work okay. But a lot of scrolling is necessary, so a search function would save even more time. 

				<br><br>

				– The ability to add items that’s not in the Home view array. This would depend on some kind of back end and login solution. Right now all the items are stored in a local JSON file. But if I move the data to a database I could make a function for adding new items.

				<br><br>

				– If I move the data to a back end and login solution I could also make the app work cross devices.
			</p>

		</section>
	
	</section>
	
	
	
</div>
</template>

<script>


export default
{
	components: 
	{
		
	},
	data() {
		return{
			images:
			[
				{ url: require('../assets/work/gouwudan/gouwudan001.png'), alt: 'Gouwudan mobile home and store mockups'},
				{ url: require('../assets/work/gouwudan/notes.jpg'), alt: 'Shopping list in Apple Notes app', caption: 'The old way – grouping items in Apple Notes', captionClass: 'caption-left'},
				{ url: require('../assets/work/gouwudan/blueprint.jpg'), alt: 'Blueprint of grocery store with zones', caption: 'Blueprint of the grocery store with zones', captionClass: 'caption-left'},
				{ url: require('../assets/work/gouwudan/gouwudan002.png'), alt: 'Gouwudan home view', caption: 'Gouwudan home view', captionClass: 'caption-left'},
				{ url: require('../assets/work/gouwudan/gouwudan003.png'), alt: 'Gouwudan store view', caption: 'Gouwudan store view', captionClass: 'caption-left'},
				{ url: require('../assets/work/gouwudan/gouwudan004.png'), alt: 'Gouwudan store view more categories', caption: 'Gouwudan store view more categories', captionClass: 'caption-left'},
			]	
		}
	}	
}
	
</script>